import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';

import HeroImage from '../components/HeroImage';
import Button from '../components/Button';
import Section from '../components/Section';

import styles from './markdown-page.module.scss';

export const MarkdownPageTemplate = ({ html, heroImage, cta }) => {
  return (
    <React.Fragment>
      {heroImage ? (
        <HeroImage imageInfo={heroImage.image} alt="TODO">
          <div className={styles.heroImageContent}>
            <h1 className="title">{heroImage.title}</h1>
            {heroImage.button ? (
              <Button inverted to={heroImage.button.url}>
                {heroImage.button.text}
              </Button>
            ) : null}
          </div>
        </HeroImage>
      ) : null}
      <section className="section">
        <div className="container">
          <div className={`${styles.content} content`} dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </section>
      {cta && cta.body ? <Section {...cta} /> : null}
    </React.Fragment>
  );
};

MarkdownPageTemplate.propTypes = {
  html: PropTypes.string.isRequired,
};

const MarkdownPage = ({
  location: { pathname, state },
  data: {
    markdownRemark: {
      html,
      frontmatter: { meta, heroImage, cta },
    },
  },
}) => {
  return (
    <Layout pathname={pathname} meta={meta} state={state}>
      <MarkdownPageTemplate html={html} heroImage={heroImage} cta={cta} />
    </Layout>
  );
};

MarkdownPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string,
      frontmatter: PropTypes.shape({
        meta: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        }).isRequired,
        cta: PropTypes.shape({
          body: PropTypes.string,
          button: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
          }),
        }),
        heroImage: PropTypes.shape({
          title: PropTypes.string,
          image: PropTypes.object,
          button: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
          }),
        }),
      }),
    }),
  }).isRequired,
};

export const markdownPageQuery = graphql`
  query markdownPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        meta {
          title
          description
        }
        cta {
          body
          button {
            text
            url
          }
        }
        heroImage {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1280, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          button {
            text
            url
          }
        }
      }
    }
  }
`;

export default MarkdownPage;
