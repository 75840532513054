import React from 'react';
import PropTypes from 'prop-types';

import styles from './HeroImage.module.scss';
import PreviewCompatibleImage from './PreviewCompatibleImage';

function HeroImage({ imageInfo, alt, children }) {
  return (
    <section className="section">
      <div className="container">
        <div className={styles.heroImage}>
          <PreviewCompatibleImage alt={alt} className={styles.img} imageInfo={imageInfo} />
          <div className={styles.children}>{children}</div>
        </div>
      </div>
    </section>
  );
}

HeroImage.propTypes = {
  fluid: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node,
  alt: PropTypes.string.isRequired,
};

export default HeroImage;
